import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCan } from "@fortawesome/free-solid-svg-icons"

import "./Sidebar.css"
const Sidebar = ({ 
    onAddNote, 
    notes, 
    onDeleteNote, 
    activeNote,
    setActiveNote }) => {

  const sortedNotes = notes.sort(( a, b ) => b.modDate - a.modDate)
        
  return (
    <div className="app-sidebar">
        <div className="app-sidebar-header">
            <h1>メモ</h1>
            <button onClick={onAddNote}></button>
        </div>
        <div className="app-sideba-notes">
            {sortedNotes.map((note) => (
            <div className={`app-sidebar-note ${activeNote === note.id && 'active'}`}
             key={note.id}
             onClick={() => setActiveNote(note.id)}
             >
                <div className="sidebar-note-title">
                    <strong>{ note.title }</strong>
                    <button onClick={ () => onDeleteNote(note.id) }>
                    <FontAwesomeIcon icon={faTrashCan}/>
                </button>
                </div>
                {/**<p>{ note.content }</p> */}
                <small>{ new Date(note.modDate).toLocaleDateString('ja-jp', {
                    hour: "2-digit",
                    minute: "2-digit",
                })}</small>
            </div>
            ))}
        </div>
    </div>
  )
}

export default Sidebar
import React from 'react'
import { useState } from 'react';
import "./Main.css"
import ReactMarkdown from 'react-markdown';

const Main = ({activeNote, onUpdateNote}) => {

  const [active, setActive] = useState(false);

  const classToggle = () => {
    setActive(!active)
  }
  
  const onEditNote = (key, value) => {
    onUpdateNote({
      ...activeNote,
      [key]: value,
      modDate: Date.now(),
    })
  }

  if(!activeNote){
    return <div className='no-active'>メモが選択されていません</div>
  }
  return (
    <div className='app-main'>
      <div className="app-main-note-edit">
        <input 
          id="title"
          type="text"
          value={activeNote.title}
          onChange={(e) => onEditNote('title', e.target.value)}
        />
        <textarea 
          id="content" 
          placeholder='内容を記入'
          value={activeNote.content}
          onChange={(e) => onEditNote('content', e.target.value)}
        ></textarea>
      </div>
      <div className={`app-main-note-preview ${active ? "slide" : ""}`}>
      <div className='btn-menu' onClick={classToggle}>{active ? "編集終了" : "編集する"}</div>
        <h1 className="preview-title">{activeNote.title}</h1>
        <ReactMarkdown className="markdown-preview">{activeNote.content}</ReactMarkdown>
      </div>
    </div>
  )
}

export default Main
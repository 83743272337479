import { useEffect, useState } from 'react';
import './App.css';
import Main from './components/Main';
import Sidebar from './components/Sidebar';
import uuid from 'react-uuid';

function App() {
  const [notes, setNotes] = useState(JSON.parse(localStorage.getItem("notes")) || []);
  const [activeNote, setActiveNote] = useState(false);

  useEffect(()=> {
    //ローカルストレージにノートを保存する
    localStorage.setItem("notes", JSON.stringify(notes))
  },[notes]);

  useEffect(() => {
    //更新後、更新した最新のノートを表示する
    if(notes.length !== 0) {
      setActiveNote(notes[0].id)
    }
  },[]);


  const onAddNote = () => {
    console.log('メモが追加されました');
    const newNote = {
      id: uuid(),
      title: "新しいメモ",
      content: "",
      modDate: Date.now(),
    }
    setNotes([...notes, newNote]);
    console.log(notes);
  };

  const onDeleteNote = (id) => {
    const filterNote = notes.filter((note) => note.id !== id);
    setNotes(filterNote);
  }

  const getActiveNote = () => {
    return notes.find((note) => note.id === activeNote);
  }

  const onUpdateNote = (updatedNote) => {
    //修正された新しいノートの配列を返す
    const updatedNotesArray = notes.map((note) => {
      if(note.id === updatedNote.id){
        return updatedNote
      } else {
        return note
      }
  });
  setNotes(updatedNotesArray)
  }

  return (
    <div className="App">
      <Sidebar 
      onAddNote={onAddNote} 
      notes={notes} 
      onDeleteNote={ onDeleteNote }
      activeNote = { activeNote }
      setActiveNote={ setActiveNote } 
      />
      <Main 
      activeNote = {getActiveNote()} 
      onUpdateNote={onUpdateNote}
      />
    </div>
  );
}

export default App;
